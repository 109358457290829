<div class="mobile-modal__inner" data-test="meter-submit-widget">
    <div class="m-portlet m-portlet--mobile mobile-modal__wrap-teaser">
        <template v-if="inRegistrationState"> </template>
        <template
            v-else-if="nextReadingStateString === readingStateType.WAITING"
        >
            <div class="teaser-block">
                <div class="col-12">
                    <div class="teaser-block__title">
                        <div class="fas fa-info-circle"></div>
                        {{ $t('widget.m.title') }}
                    </div>
                    <div class="teaser-block__text">
                        {{ $t('widget.m.waiting.text', {contractId: contractId,
                        nextReadingAt: nextReadingAtFiltered} )}}
                    </div>
                    <span class="teaser-block__triangle"></span>
                </div>
            </div>
        </template>
        <template
            v-else-if="nextReadingStateString === readingStateType.WAITING_PAST"
        >
            <div class="teaser-block">
                <div class="col-12">
                    <div class="teaser-block__title">
                        <div class="fas fa-info-circle"></div>
                        {{ $t('widget.m.title') }}
                    </div>
                    <div class="teaser-block__text">
                        {{ $t('widget.m.moveInMissing.text', {contractId:
                        contractId, nextReadingAt: nextReadingAtFiltered} )}}
                    </div>
                    <span class="teaser-block__triangle"></span>
                </div>
            </div>
        </template>
        <template
            v-else-if="nextReadingStateString === readingStateType.MOVE_IN_MISSING"
        >
            <div class="teaser-block">
                <div class="col-12">
                    <div class="teaser-block__title">
                        <div class="fas fa-info-circle"></div>
                        {{ $t('widget.m.title') }}
                    </div>
                    <div class="teaser-block__text">
                        {{ $t('widget.m.moveInMissing.text', {contractId:
                        contractId, nextReadingAt: nextReadingAtFiltered} )}}
                    </div>
                    <span class="teaser-block__triangle"></span>
                </div>
            </div>
        </template>
        <template
            v-else-if="nextReadingStateString === readingStateType.MOVE_OUT_MISSING"
        >
            <div class="teaser-block">
                <div class="col-12">
                    <div class="teaser-block__title">
                        <div class="fas fa-info-circle"></div>
                        {{ $t('widget.m.title') }}
                    </div>
                    <div class="teaser-block__text">
                        {{ $t('widget.m.moveOutMissing.text', {contractId:
                        contractId, nextReadingAt: nextReadingAtFiltered} )}}
                    </div>
                    <span class="teaser-block__triangle"></span>
                </div>
            </div>
        </template>
        <template
            v-else-if="nextReadingStateString === readingStateType.WAITING"
        >
            <div class="teaser-block">
                <div class="col-12">
                    <div class="teaser-block__title">
                        <div class="fas fa-info-circle"></div>
                        {{ $t('widget.m.title') }}
                    </div>
                    <div class="teaser-block__text">
                        {{ $t('widget.m.moveOutMissing.text', {contractId:
                        contractId, nextReadingAt: nextReadingAtFiltered} )}}
                    </div>
                    <span class="teaser-block__triangle"></span>
                </div>
            </div>
        </template>
        <template v-else-if="turnusNowOrInRange">
            <div class="teaser-block">
                <div class="col-12">
                    <div class="teaser-block__title">
                        <div class="fas fa-info-circle"></div>
                        {{ $t('widget.m.title') }}
                    </div>
                    <div class="teaser-block__text">
                        {{ $t('widget.m.turnusNow.text', {contractId:
                        contractId, startDate: turnusRangeStartDateFormatted,
                        endDate: turnusRangeEndDateFormatted} )}}
                    </div>
                    <span class="teaser-block__triangle"></span>
                </div>
            </div>
        </template>
        <div class="m-portlet__body mx-3">
            <div class="box-title static-title">
                <i class="fas fa-tachometer-alt readingHistory-icon"></i>
                {{ $t('widget.ms.title') }}
            </div>
            <div
                class="row mobile-modal__wrap mx-0 pb-3 mobile-modal__wrap-teaser"
            >
                <template v-if="isReadingDetailsLoading">
                    <div class="col-12">
                        <content-loader
                            height="40"
                            primaryColor="var(--secondary)"
                            secondaryColor="var(--primary)"
                            data-test="CONTENT-LOADER"
                        ></content-loader>
                    </div>
                </template>
                <template v-else>
                    <template v-if="isReadingDetailsError">
                        <div class="col-12">
                            {{ $t('widgets.load.error.message') }}
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-12 text-center d-flex flex-column">
                            <h3 class="mt-auto">
                                {{ $t('widget.ms.counter') }}
                                <span v-if="counter.counterNumber">
                                    #{{ counter.counterNumber }}
                                </span>
                            </h3>
                            <div class="mb-auto">
                                {{ $t('widget.ms.whereCounter') }}
                                <i
                                    class="fas fa-question-circle text-primary-color font-size-12"
                                    v-tooltip.top="$t('widget.ms.whereCounter.tooltip')"
                                ></i>
                            </div>
                        </div>
                        <div class="col-12 pr-0 mt-4 mt-lg-0">
                            <div>
                                <div
                                    class="row"
                                    v-for="(reading, idx) in getDetailsReadingsAtSelectedDate()"
                                >
                                    <div
                                        class="col-12 col-xl-4 mt-4 mt-xl-0 font-weight-bold"
                                    >
                                        {{ $t('widget.ms.newStatus') }}<template
                                            v-if="multiRegister"
                                            >{{ registerDisplay(reading)
                                            }}</template
                                        >:
                                    </div>
                                    <div class="col-12 col-xl-4 mt-4 mt-xl-0">
                                        <vue-numeric
                                            class="form-control readingInput-obis"
                                            :empty-value="0"
                                            :min="0"
                                            @input="readingInputDone(reading)"
                                            :precision="0"
                                            separator="."
                                            v-model="reading.value"
                                            :id="'reading_submit_' + idx"
                                        ></vue-numeric>
                                    </div>
                                    <div
                                        class="col-12 col-xl-4 mt-4 mt-xl-0"
                                        v-if="idx === 0"
                                    >
                                        <input
                                            v-model="readAtDate"
                                            type="date"
                                            class="form-control meter-date-input d-inline-block"
                                        />
                                    </div>
                                    <div
                                        class="col-12 col-xl-4 mt-4 mt-xl-0"
                                        v-else
                                    ></div>
                                </div>
                            </div>
                            <div
                                class="col-12 fullWithArea-submitButtonHint-inbetweenInvoice text-end"
                                v-if="!forceInvoiceDisabled"
                            >
                                <div class="form-group form-check">
                                    <label
                                        :class="['form-check-label', displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : '']"
                                    >
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="meterReadSubmit_forceInvoice"
                                            v-model="details.forceInvoice"
                                            :disabled="forceInvoiceDisabled"
                                        />
                                        {{
                                        $t('widget.ms.createInterimStatement')
                                        }}
                                        <i></i>
                                        <span></span>
                                        <i
                                            class="fas fa-info-circle"
                                            v-if="forceInvoiceDisabled"
                                            v-tooltip.bottom="$tc('widget.ms.forceInvoice.notAllowed', daysUntilNextInbetweenInvoice, {days: daysUntilNextInbetweenInvoice})"
                                        ></i>
                                        <i
                                            class="fas fa-info-circle text-primary-color font-size-12"
                                            v-if="!forceInvoiceDisabled"
                                            v-tooltip.bottom="$t('widget.ms.forceInvoice.allowed')"
                                        ></i>
                                    </label>
                                </div>
                            </div>

                            <messages-block
                                class="velbert-messages-block"
                                :success="successInformation"
                                :errors="errorInformation"
                            ></messages-block>

                            <div v-if="showReasonInput">
                                <span
                                    :class="[displayElement('bold-date-class') ? 'font-weight-bold' : '']"
                                >
                                    {{ $t('widget.ms.reason') }}
                                </span>
                                <v-select
                                    class="select-implausible-reason"
                                    v-if="allowImplausibleMeterReads && implausibleReasonExplanationListVelbert.length > 0"
                                    @input="implausibleReasonExplanationSelectedVelbert"
                                    :options="implausibleReasonExplanationListVelbert"
                                    :class="[reasonExplanationError ? 'is-invalid' : '']"
                                >
                                </v-select>
                            </div>

                            <div
                                v-if="askForErrorConfirmation"
                                class="form-group form-check"
                            >
                                <label
                                    :class="['form-check-label', displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : '']"
                                >
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="meterReadSubmit_errorConfirmation"
                                        v-model="errorConfirmation"
                                    />
                                    {{ $t('widget.ms.error.confirmation') }}
                                    <i></i>
                                    <span></span>
                                </label>
                            </div>

                            <div
                                v-if="readAtOutOfAllowedRange"
                                class="alert alert-warning"
                            >
                                {{ $t('widget.ms.readAt.out.of.range', {
                                minDate: minDateFormatted, maxDate:
                                maxDateFormatted}) }}
                            </div>

                            <div class="d-flex justify-content-end">
                                <button
                                    v-if="nextReadingStateString !== readingStateType.WAITING && !inRegistrationState"
                                    class="btn btn-primary mt-auto ms-auto ms-md-4 ms-lg-auto ms-xl-4"
                                    @click="submit"
                                    :disabled="submitPending || (askForErrorConfirmation && !errorConfirmation)"
                                >
                                    {{ $t('widget.ms.transfer') }}
                                </button>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
</div>
