import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import WithRender from './meter-submit-widget.html';
import vSelect from 'vue-select';

import * as Sentry from '@sentry/browser';

import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import _ from 'lodash';
import VueNumeric from 'vue-numeric';
import moment from 'moment';

import { SurveyWidget } from '@/components/widgets/survey-widget';

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Auth from '@/interfaces/Auth';
import Reading, { ReadingState } from '@/interfaces/Reading';
import SubmitReading from '@/interfaces/SubmitReading';
import Format from '@/interfaces/Format';
import Tracking from '@/interfaces/Tracking';
import Commodity from '@/interfaces/Commodity';
import { TranslateResult } from 'vue-i18n';
import { AxiosResponse } from 'axios';
import { customParseInt } from '@/customParseInt';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const readingProvider = IocContainer.get<Reading>(SERVICES.READING);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);
const commodityProvider = IocContainer.get<Commodity>(SERVICES.COMMODITY);

@WithRender
@Component({
    components: {
        // External packages
        datetime: Datetime,
        VueNumeric,
        vSelect,
        'survey-widget': SurveyWidget,
        'messages-block': MessagesBlock,
    },
    filters: {
        date: formatProvider.date(),
        datetime: formatProvider.datetime(),
    },
})
export class MeterSubmitWidget extends Base {
    public submitPending = false;
    public invoiceSimulationPending = false;
    public errorCode = '';
    public errorConfirmation = false;
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];
    public reason = '';
    public previousReadingValue = '';
    public reasonExplanationError = false;
    public implausibleReasonExplanation = '';
    public selectedImplausibleReasonExplanationOther = false;
    public simulateInvoiceDateUntil: Record<any, any> = {
        label: this.$t('widget.ms.simulate.invoice.today'),
        key: 'today',
    };
    protected editActive = false;
    protected readAtDate = moment(this.submitData.maxDate).format('YYYY-MM-DD');

    protected details: SubmitReading = {
        contractId: this.$store.state.contracts.contractId,
        forceImplausibleSaving: 0,
        forceInvoice: false,
        readAt: this.readAtDate,
        readings: [],
        implausibleReasonExplanation: '',
    };
    protected showSurvey = false;
    protected showInvoiceSimulationForm = false;

    protected elements = {
        bsw: [
            'bold-date-class',
            'calendar-icon',
            'default-btn',
            'float-right-class',
            'vue-numeric',
        ],
        sachsenenergie: ['no-placeholder-next-reading'],
        shell: ['force-invoice-with-reading'],
        wdenergy: ['no-div-next-reading'],
        'wdenergy-us': ['no-div-next-reading'],
        'dsp-*': ['force-invoice-with-reading'],
        'sw-ahrensburg': ['force-invoice-with-reading'],
        eoptimum: ['align-checkbox', 'font-size-12', 'hide-edit-button'],
    };

    get readingState(): any {
        return this.$store.getters['reading/getState'](
            this.$store.state.contracts.contractId
        );
    }

    get readingStateType(): typeof ReadingState {
        return ReadingState;
    }

    get counter(): any {
        return this.readingState.counter;
    }

    get readings(): any {
        return this.readingState.readings;
    }

    get submitData(): any {
        return this.readingState.submit;
    }

    get registers(): any {
        return this.counter.registers.filter((register) => {
            // active
            const active =
                moment(register.activeFrom) <= moment() &&
                (register.activeUntil == '' ||
                    moment(register.activeUntil) >= moment());
            // expired, but activeUntil matches contract end date
            const expired =
                this.contractEndDate &&
                moment(register.activeFrom) <= moment() &&
                moment(register.activeUntil) <= moment() &&
                moment(register.activeUntil).format('YYYY-MM-DD') ==
                    this.contractEndDate;
            return active || expired;
        });
    }

    get multiRegister(): boolean {
        return this.counter.registers.length > 1;
    }

    public formatReadingValue(reading, register): string {
        if (this.currentClient === 'eoptimum') {
            register.precision = 0;
        }
        return parseFloat(reading.value).toLocaleString('de-DE', {
            minimumFractionDigits: register.precision,
            maximumFractionDigits: register.precision,
        });
    }

    public registerMaxValue(reading): number {
        const currentRegister = this.registerInformation(reading);
        if (currentRegister) {
            return Math.pow(10, parseInt(currentRegister.digits, 10)) - 1;
        }
        return 0;
    }

    protected dateFilter(d): string {
        if (d === null) {
            return '';
        }
        return moment(d).format('DD.MM.YYYY');
    }

    protected mounted(): void {
        trackingProvider.showMeterReading();
        this.details.contractId = this.$store.state.contracts.contractId;
        this.setInitData();
    }

    protected destroyed(): void {
        trackingProvider.hideMeterReading();
    }

    @Watch('readAtDate')
    @Watch('details.readAt')
    protected onReadAtChange(newVal): void {
        if (!isNaN(Date.parse(newVal))) {
            this.details.readAt = moment(newVal).format('YYYY-MM-DD');
            this.readAtDate = this.details.readAt;
        }
    }

    @Watch('minDate')
    @Watch('maxDate')
    protected onDateChange(): void {
        this.setInitData();
    }

    get showInvoiceSimulation(): boolean {
        if (
            this.isActivateInvoiceSimulation &&
            this.readings &&
            this.readings.length > 0
        ) {
            return true;
        }
        return false;
    }

    get nextReadingAt(): string {
        return moment(this.readingState.next.date).toISOString();
    }

    get nextReadingAtFiltered(): any {
        return formatProvider.date()(this.nextReadingAt);
    }

    get nextReadingStateString(): any {
        return this.readingState.next.state;
    }

    get isMoveInToday(): boolean {
        return (
            this.nextReadingAt === moment().toISOString() &&
            this.nextReadingStateString === ReadingState.MOVE_IN_MISSING
        );
    }

    get maxDate(): string {
        return moment(this.submitData.maxDate).endOf('day').toISOString();
    }

    get minDate(): string {
        return moment(this.submitData.minDate).startOf('day').toISOString();
    }

    get maxDateFormatted(): any {
        return formatProvider.date()(this.maxDate);
    }

    get minDateFormatted(): any {
        return formatProvider.date()(this.minDate);
    }

    get readAtOutOfAllowedRange(): boolean {
        const readAt = moment(this.readAtDate);
        const min = moment(this.minDate);
        const max = moment(this.maxDate);
        if (readAt < min || readAt > max) {
            return true;
        }
        return false;
    }

    get commodity(): TranslateResult {
        return this.$t(
            commodityProvider.getCommodityType(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract,
                'meter-submit'
            )
        );
    }

    get commodityPrice(): TranslateResult {
        return this.$t(
            commodityProvider.getCommodityTypeWorkingPrice(
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).contract
            )
        );
    }

    get readingsSorted(): Record<any, any> {
        return _.sortBy(this.readings, (reading) => {
            return reading.readAt;
        });
    }

    get forceMoveInReading(): boolean {
        return (
            this.$store.state.settings.readingForceMovein &&
            this.nextReadingStateString === ReadingState.MOVE_IN_MISSING
        );
    }

    get forceDateSelect(): any {
        return this.$store.state.settings.readingForceDateSelect;
    }

    private isSelectedDateInRange(
        dateFrom: string = '',
        dateUntil: string = ''
    ): boolean {
        const dateReadAt = moment(this.details.readAt);
        const dateFromMoment = moment(dateFrom);
        const dateUntilMoment = moment(dateUntil);
        const daysAfterStart: number = dateReadAt.diff(dateFromMoment, 'days');
        const daysUntilEnd: number = dateUntilMoment.diff(
            dateReadAt,
            'days',
            true
        );

        if (dateFrom.length > 0 && dateUntil.length > 0) {
            return daysAfterStart >= 0 && daysUntilEnd >= 0;
        } else if (dateFrom.length > 0) {
            return daysAfterStart >= 0;
        } else if (dateUntil.length > 0) {
            return daysUntilEnd >= 0;
        }
        return false;
    }

    protected getRegistersDisplay(mostRecent: boolean = false): any {
        const registers: any = mostRecent
            ? this.registersDisplayMostRecent
            : this.registersDisplay;
        return registers.filter((registerData) => {
            return this.isSelectedDateInRange(
                registerData.register.activeFrom,
                registerData.register.activeUntil
            );
        });
    }

    private get registersDisplayMostRecent(): any {
        return this.registersDisplayFull.map((reg) => {
            reg.readings = reg.readings
                .sort((a, b) => (a.readAt > b.readAt ? -1 : 1))
                .slice(0, 3)
                .reverse();
            return reg;
        });
    }

    private get registersDisplayFull(): any {
        return this.registers.map((reg) => {
            const readingsSortedObis = this.readingsSorted.filter(
                (readingInner) => {
                    return readingInner.obisCode === reg.obis;
                }
            );
            return {
                register: reg,
                readings: readingsSortedObis,
            };
        });
    }

    private get registersDisplay(): any {
        const data: any[] = this.registers.map((reg) => {
            const readingValues: any[] = [];

            let readingsSortedObis = this.readingsSorted.filter(
                (readingInner) => {
                    return readingInner.obisCode === reg.obis;
                }
            );

            if (readingsSortedObis.length >= 1) {
                readingValues.push(readingsSortedObis.shift());
            }

            const lastReading = readingsSortedObis.pop();
            if (readingsSortedObis.length >= 1) {
                if (readingsSortedObis.length > 2) {
                    readingsSortedObis = _.shuffle(readingsSortedObis);
                }
                readingValues.push(readingsSortedObis.shift());
            }

            if (this.currentClient === 'sachsenenergie') {
                if (lastReading) {
                    readingValues.push(lastReading);
                }
            } else {
                readingValues.push(lastReading);
            }

            return {
                register: reg,
                readings: readingValues,
            };
        });

        return data;
    }

    get datetimePhrases(): Record<'ok' | 'cancel', any> {
        return {
            ok: this.$t('datetime.ok'),
            cancel: this.$t('datetime.cancel'),
        };
    }

    get daysUntilNextInbetweenInvoice(): number | null {
        if (
            this.lastInvoiceDate &&
            this.daysBetweenInvoices &&
            this.readAtDate
        ) {
            const lastInvoiceDate = moment(this.lastInvoiceDate);
            const readAt = moment(this.readAtDate);
            return (
                this.daysBetweenInvoices - readAt.diff(lastInvoiceDate, 'days')
            );
        }
        return null;
    }

    get showReasonInput() {
        return (
            this.$store.state.settings.allowImplausibleMeterReads &&
            this.errorCode === 'request_is_invalid' &&
            this.previousReadingValue ===
                this.getDetailsReadingsAtSelectedDate()[0].value
        );
    }

    get simulateInvoiceDateUntilOptions(): Record<any, any> {
        const options: Record<any, any> = [];
        options.push({
            label: this.$t('widget.ms.simulate.invoice.today'),
            key: 'today',
        });
        options.push({
            label: this.$t('widget.ms.simulate.invoice.cycle'),
            key: 'cycle',
        });
        return options;
    }

    get implausibleReasonExplanationList(): string[] {
        if (
            this.allowImplausibleMeterReads &&
            this.$store.state.settings.implausibleReasonExplanationOptions
                .length > 0
        ) {
            const reasons =
                this.$store.state.settings.implausibleReasonExplanationOptions
                    .trim()
                    .split(',')
                    .map((reason) => {
                        return reason.trim();
                    });
            if (
                this.$store.state.settings
                    .allowImplausibleReasonExplanationOther
            ) {
                reasons.push(
                    this.$t('widget.ms.reason.other').toLocaleString()
                );
            }
            return reasons;
        } else {
            return [];
        }
    }

    get allowImplausibleMeterReads() {
        return !!+this.$store.state.settings.allowImplausibleMeterReads;
    }

    get showImplausibleReasonExplanationOther() {
        return (
            this.selectedImplausibleReasonExplanationOther ||
            (this.allowImplausibleMeterReads &&
                this.implausibleReasonExplanationList.length === 0)
        );
    }

    get isActivateInvoiceTriggering() {
        return this.$store.state.settings.activateInvoiceTriggering;
    }

    get isActivateInvoiceSimulation() {
        return this.$store.state.settings.activateInvoiceSimulation;
    }

    get askForErrorConfirmation() {
        if (this.currentClient === 'velbert' || this.currentClient === 'ele') {
            if (this.errorCode === '043') {
                return true;
            }
        }
        return false;
    }

    protected registerInformation(reading) {
        const currentRegister = this.registers.filter((register) => {
            return register.obis === reading.obis;
        });
        if (currentRegister.length > 0) {
            return currentRegister[0];
        }
        return null;
    }

    protected registerDisplay(reading) {
        const currentRegister = this.registerInformation(reading);
        if (currentRegister) {
            return ' ' + currentRegister.description;
        }
        return '';
    }

    protected registerPrecision(reading) {
        const currentRegister = this.registerInformation(reading);
        if (currentRegister) {
            return parseInt(currentRegister.precision, 10);
        }
        return 0;
    }

    protected setInitData() {
        if (this.forceMoveInReading) {
            const minDate = moment(this.minDate);
            this.readAtDate = minDate.format('YYYY-MM-DD');
        } else if (!this.forceDateSelect) {
            const maxDate = moment(this.maxDate);
            this.readAtDate = maxDate.format('YYYY-MM-DD');
        } else {
            this.readAtDate = moment(this.maxDate).format('YYYY-MM-DD');
            this.editActive = true;
        }
        this.details.readings = [];
        this.registers.forEach((register) => {
            this.details.readings.push({
                obis: register.obis,
                value: 0,
            });
        });
    }

    protected setEditActive(val: boolean) {
        if (this.forceMoveInReading) {
            this.editActive = false;
        } else {
            this.editActive = val;
        }
    }

    get contractEndDate() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.endDate;
    }

    get lastInvoiceDate() {
        const lastInvoiceDate = this.submitData.lastInvoiceDate;
        if (this.readings && this.readings.length > 0) {
            let maxTriggerInvoiceReading = null;
            this.readings.forEach((reading) => {
                if (
                    reading.triggerInvoice === '1' &&
                    moment(reading.readAt) > moment(lastInvoiceDate)
                ) {
                    maxTriggerInvoiceReading = reading.readAt;
                }
            });
            return moment(maxTriggerInvoiceReading) > moment(lastInvoiceDate)
                ? maxTriggerInvoiceReading
                : lastInvoiceDate;
        }
        return lastInvoiceDate;
    }

    get daysBetweenInvoices() {
        return parseInt(this.$store.state.settings.daysBetweenInvoices);
    }

    get forceInvoiceDisabled() {
        if (
            this.lastInvoiceDate &&
            this.daysBetweenInvoices >= 0 &&
            this.readings.length > 0 &&
            (!this.contractEndDate ||
                moment(this.readAtDate) < moment(this.contractEndDate))
        ) {
            const lastInvoiceDate = moment(this.lastInvoiceDate);
            const readAt = moment(this.readAtDate);

            return (
                readAt.diff(lastInvoiceDate, 'days') <= this.daysBetweenInvoices
            );
        }
        return true;
    }

    protected getReadingsFromRegister(register: any): string {
        return register.readings;
    }

    protected getRegisterTitle(register: any): string {
        let title: string = [
            register.register.obis,
            register.register.description,
        ]
            .filter(Boolean)
            .join(' - ');

        if (
            register.register.activeUntil &&
            register.register.activeUntil.length > 0
        ) {
            title +=
                ' (' +
                this.$t('meter.reading.valid.until') +
                ' ' +
                this.dateFilter(
                    moment(register.register.activeUntil).toISOString()
                ) +
                ')';
        }

        return title;
    }

    protected getDetailsReadingsAtSelectedDate(): any {
        return this.details.readings.filter((reading) => {
            return this.isObisActiveOnSelectedDate(reading.obis);
        });
    }

    protected onHistoryLoaded(): void {
        this.$root.$emit('historyLoaded');
    }

    private isObisActiveOnSelectedDate(obisCode: string): boolean {
        const activeRegisters = this.getRegistersDisplay();
        for (let i = 0; i < activeRegisters.length; ++i) {
            if (activeRegisters[i].register.obis == obisCode) {
                return true;
            }
        }
        return false;
    }

    protected toggleInvoiceSimulationForm(): void {
        this.showInvoiceSimulationForm = !this.showInvoiceSimulationForm;
    }

    protected async simulateInvoice() {
        if (authProvider.isAuthenticated()) {
            this.invoiceSimulationPending = true;
            await readingProvider
                .invoiceSimulate(
                    this.$store.state.contracts.contractId,
                    this.simulateInvoiceDateUntil
                        ? this.simulateInvoiceDateUntil.key
                        : 'today'
                )
                .then(
                    (response) => {
                        this.invoiceSimulationPending = false;

                        if (response.data.success) {
                            this.successInformation = [];
                            this.errorInformation = [];
                            this.successInformation.push({
                                key: 'success',
                                message: this.$t(
                                    'widget.ms.simulate.invoice.success'
                                ),
                            });
                            this.showInvoiceSimulationForm = false;
                        } else {
                            this.errorInformation = [];
                            this.errorInformation.push({
                                key: 'error',
                                message: this.$t(
                                    'widget.ms.simulate.invoice.error'
                                ),
                            });
                        }
                    },
                    (error) => {
                        this.invoiceSimulationPending = false;

                        this.successInformation = [];
                        this.errorInformation.push({
                            key: 'error',
                            message: this.$t('general.error').toLocaleString(),
                        });
                        Sentry.captureException(new Error(error));
                    }
                );
        }
    }

    get hasErrorCode() {
        return (errorCode) => {
            return (
                this.errorInformation.filter((error) => error.key === errorCode)
                    .length > 0
            );
        };
    }

    private addErrorInformation(
        key: string = '',
        message: string = '',
        submitPending: boolean = false
    ): boolean {
        if (key.length > 0 && message.length == 0) {
            message = this.$t(key).toLocaleString();
        }

        if (typeof message === 'object') {
            this.errorInformation.push(message);
        } else {
            this.errorInformation.push({
                key: key,
                message: message,
            });
        }
        this.submitPending = submitPending;
        return false;
    }

    /**
     * Checks whether the user put a value for each of
     * the registers (which are active at the selected date) or not.
     */
    private get isValidEnteredReadingData(): boolean {
        return (
            this.getDetailsReadingsAtSelectedDate().filter((reading) => {
                return (
                    reading.value !== '' &&
                    reading.value !== null &&
                    reading.value > 0
                );
            }).length == this.getDetailsReadingsAtSelectedDate().length
        );
    }

    private get isImplausibleReasonOther(): boolean {
        return (
            (this.allowImplausibleMeterReads &&
                this.implausibleReasonExplanationList.length > 0 &&
                this.implausibleReasonExplanation === '') ||
            (this.allowImplausibleMeterReads &&
                this.implausibleReasonExplanationList.length > 0 &&
                this.implausibleReasonExplanation ===
                    this.$t('widget.ms.reason.other').toLocaleString() &&
                this.reason.length === 0)
        );
    }

    protected async submit() {
        if (authProvider.isAuthenticated()) {
            this.submitPending = true;
            this.errorInformation = [];
            this.successInformation = [];
            this.reasonExplanationError = false;

            if (!this.isValidEnteredReadingData) {
                return this.addErrorInformation(
                    'widget.ms.missingReadingValue'
                );
            }
            if (!this.details.readAt) {
                return this.addErrorInformation('widget.ms.missingReadingDate');
            }

            if (this.showReasonInput && this.isImplausibleReasonOther) {
                this.reasonExplanationError = true;
                this.submitPending = false;
                return false;
            }

            await readingProvider
                .submitMeterRead(this.buildSubmitDetails())
                .then(
                    (response) => this.handleSubmit(response),
                    (error) => this.handleSubmitError(error)
                );
        }
    }

    private buildSubmitDetails() {
        const submitDetails = { ...this.details };

        submitDetails.readAt = submitDetails.readAt
            .replace('T', ' ')
            .substring(0, 19);
        submitDetails.implausibleReasonExplanation =
            this.reason.length > 0
                ? this.reason
                : this.implausibleReasonExplanation;
        submitDetails.forceImplausibleSaving =
            submitDetails.implausibleReasonExplanation &&
            submitDetails.implausibleReasonExplanation.length > 0 &&
            this.showReasonInput
                ? 1
                : 0;
        submitDetails.readings = this.getDetailsReadingsAtSelectedDate();

        if (this.askForErrorConfirmation && this.errorConfirmation) {
            submitDetails.forceImplausibleSaving = 1;
            submitDetails.implausibleReasonExplanation = 'errorConfirmed';
        }
        return submitDetails;
    }

    private handleSubmit(response: AxiosResponse<any, any>) {
        this.submitPending = false;
        this.reasonExplanationError = false;
        this.reason = '';
        this.errorCode = response.data.errorCode || '';

        if (response.data.success) {
            this.handleSubmitResultSuccess(response);
        } else {
            this.handleSubmitResultError(response);
        }
    }

    private handleSubmitError(error: any): void {
        this.reasonExplanationError = false;
        this.successInformation = [];
        this.addErrorInformation('general.error');
        Sentry.captureException(new Error(error));
    }

    private handleSubmitResultSuccess(response: AxiosResponse<any, any>): void {
        if (typeof response.data.messageLocalized === 'object') {
            this.successInformation.push(response.data.messageLocalized);
        } else if (typeof response.data.messageLocalized === 'string') {
            this.successInformation.push({
                key: '',
                message: response.data.messageLocalized,
            });
        }
        this.$store.dispatch(
            'reading/next',
            this.$store.state.contracts.contractId
        );
        this.$store.dispatch(
            'reading/details',
            this.$store.state.contracts.contractId
        );
        this.$store
            .dispatch('reading/history', this.$store.state.contracts.contractId)
            .then(this.onHistoryLoaded);
        this.details.readAt = moment().toISOString();
        this.implausibleReasonExplanation = '';
        trackingProvider.masterDataChangesMeterReading();

        this.showSurvey = true;
    }

    private handleSubmitResultError(response: AxiosResponse<any, any>): void {
        if (
            typeof response.data.errorData !== 'undefined' &&
            response.data.nestedErrorData.errorCode &&
            response.data.nestedErrorData.errorCode ===
                'reading_usage_sum_is_not_plausible'
        ) {
            const value = response.data.errorData.value;
            this.addErrorInformation(
                '',
                this.$t('widget.ms.reading.sum.is.not.plausible', {
                    value,
                }).toLocaleString(),
                false
            );
        } else {
            this.previousReadingValue =
                this.getDetailsReadingsAtSelectedDate()[0].value;

            if (response.data.nestedErrorData) {
                this.addErrorInformation(
                    '',
                    this.getCustomErrorMessage(response.data),
                    false
                );
            } else if (typeof response.data.messageLocalized === 'string') {
                if (
                    response.data.errorCode &&
                    response.data.errorCode === 'request_is_invalid'
                ) {
                    this.addErrorInformation(
                        'general.error.invalid.request',
                        '',
                        false
                    );
                } else {
                    this.addErrorInformation(
                        '',
                        this.customMessageLocalized(
                            response.data.messageLocalized
                        ),
                        false
                    );
                }
            } else if (
                response.data.errorMessages &&
                response.data.errorMessages.length > 0
            ) {
                this.errorInformation = response.data.errorMessages;
            } else {
                this.addErrorInformation('general.error', '', false);
            }
        }
    }

    private getCustomErrorMessage(data: any): any {
        if (data.nestedErrorData) {
            if (
                data.nestedErrorData.errorCode ===
                'reading_usage_sum_is_not_plausible'
            ) {
                const firstError: any = data.nestedErrorData;
                const contractReadingPlausability =
                    firstError.data &&
                    firstError.data.usage_slots &&
                    firstError.data.usage_slots.contract_reading_plausibility
                        ? firstError.data.usage_slots
                              .contract_reading_plausibility
                        : null;

                if (contractReadingPlausability !== null) {
                    const message = {
                        key: 'reading_usage_sum_is_not_plausible',
                        message: this.$t(
                            'meter.reading.error.usage.sum.not.plausible',
                            {
                                usage: parseInt(
                                    contractReadingPlausability.usage,
                                    10
                                ),
                                periodUsageStartDate: moment(
                                    contractReadingPlausability.periodUsageStartDate
                                )
                                    .locale('de')
                                    .format('D. MMMM Y'),
                                periodUsageEndDate: moment(
                                    contractReadingPlausability.periodUsageEndDate
                                )
                                    .locale('de')
                                    .format('D. MMMM Y'),
                                expectedUsage: parseInt(
                                    contractReadingPlausability.expectedUsage,
                                    10
                                ),
                            }
                        ),
                    };

                    return message;
                }
            }

            if (
                data.nestedErrorData.errorCode ===
                'exists_previous_reading_with_higher_value'
            ) {
                const firstError: any = data.nestedErrorData;
                const errorData: any = firstError.data ? firstError.data : null;

                if (errorData !== null) {
                    const message = {
                        key: 'exists_previous_reading_with_higher_value',
                        message: this.$t(
                            'meter.reading.error.previous.reading.with.higher.value',
                            {
                                value: errorData.value,
                                date: errorData.date,
                            }
                        ),
                    };

                    return message;
                }
            }

            if (
                data.nestedErrorData.errorCode ===
                'exists_future_reading_with_smaller_value'
            ) {
                const firstError: any = data.nestedErrorData;
                const errorData: any = firstError.data ? firstError.data : null;

                if (errorData !== null) {
                    const message = {
                        key: 'exists_future_reading_with_smaller_value',
                        message: this.$t(
                            'meter.reading.error.future.reading.with.smaller.value',
                            {
                                value: errorData.value,
                                date: errorData.date,
                            }
                        ),
                    };

                    return message;
                }
            }

            if (
                data.nestedErrorData.errorCode ===
                'reading_date_is_in_billed_period'
            ) {
                return {
                    key: 'reading_date_is_in_billed_period',
                    message: this.$t(
                        'meter.reading.error.reading.date.is.in.billed.period'
                    ),
                };
            }
        }

        return data.messageLocalized;
    }

    private customMessageLocalized(message) {
        if (this.currentClient === 'sachsenenergie') {
            return message.includes('Zählerstand erfolgreich abgespeichert')
                ? this.$t('widget.ms.success').toLocaleString()
                : message;
        }

        return message;
    }

    private implausibleReasonExplanationSelected(val) {
        this.selectedImplausibleReasonExplanationOther =
            val === this.$t('widget.ms.reason.other').toLocaleString();
    }

    protected closeSelf() {
        if (screen.width < 768) {
            return this.$emit('forceCloseWidget');
        }
        return this.$emit('closeWidget');
    }
}
