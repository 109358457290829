import WithRender from './velbert.html';
import Password from 'vue-password-strength-meter';
import Component from 'vue-class-component';
import { ChangeCustomerDataWidget } from '@/components/widgets/change-customer-data-widget';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import VelbertAuth from '@/interfaces/VelbertAuth';
import Auth from '@/interfaces/Auth';
import Base from '@/mixins/base';
import { MessagesBlock } from '@/components/snippets/messages';

import VueButtonSpinner from 'vue-button-spinner';
import { handleNavigationFailure } from '@/router/utils';

const authVelbert = IocContainer.get<VelbertAuth>(SERVICES.AUTH_WAERMEENERGIE);

@WithRender
@Component({
    components: {
        'change-customer-data-widget': ChangeCustomerDataWidget,
        'password-strength-meter': Password,
        'vue-button-spinner': VueButtonSpinner,
        'messages-block': MessagesBlock,
    },
})
export class RegisterPageVelbert extends Base {
    protected isLoading = false;
    protected status = '';
    protected currentTab = 1;

    private auth = IocContainer.get<Auth>(SERVICES.AUTH);
    private data: {
        firstname: string;
        surname: string;
        customerNo: number | null;
        authUserName: string | null;
        password: string;
        passwordConfirmation: string;
        email: string;
        emailConfirmation: string;
        agreementTerms: boolean;
        agreementDigitalPost: boolean;
    } = {
        firstname: '',
        surname: '',
        customerNo: null,
        authUserName: null,
        password: '',
        passwordConfirmation: '',
        email: '',
        emailConfirmation: '',
        agreementTerms: false,
        agreementDigitalPost: true,
    };
    private errorsBag: {
        firstname?: any;
        surname?: any;
        customerNo?: any;
        authUserName?: any;
        password?: any;
        passwordConfirmation?: any;
        email?: any;
        emailConfirmation?: any;
        agreementTerms?: any;
        nameString1?: any;
        nameString2?: any;
    } = {
        firstname: null,
        surname: null,
        customerNo: null,
        authUserName: null,
        password: null,
        passwordConfirmation: null,
        email: null,
        emailConfirmation: null,
        agreementTerms: null,
        nameString1: null,
        nameString2: null,
    };
    private failInformation: Record<any, any> = [];
    private successInformation: Record<any, any> = [];

    private register() {
        this.formatEmailAddress();
        this.clearErrorsBag();
        if (this.validateSubmit()) {
            this.isLoading = true;
            this.failInformation = [];
            this.successInformation = [];
            authVelbert.validateUsername(this.data.authUserName).then(
                (response) => {
                    if (this.responseSuccess(response, false)) {
                        this.registerAction();
                    } else {
                        this.isLoading = false;
                    }
                },
                (error) => {
                    this.isLoading = false;
                    console.error(error);
                }
            );
        }
    }

    private registerAction() {
        authVelbert.register(this.data).then(
            (response) => {
                this.isLoading = false;
                if (response.data && response.status === 422) {
                    this.errorsBag = response.data.errors;
                } else if (
                    response.data.success === false &&
                    response.data.messageLocalized !== ''
                ) {
                    this.failInformation.push({
                        key: '',
                        message: response.data.messageLocalized,
                    });
                } else if (response.data.success === false) {
                    this.failInformation.push({
                        key: '',
                        message: this.$t('register.errorOccurred'),
                    });
                } else if (
                    response.data.authToken &&
                    response.data.authToken !== ''
                ) {
                    this.auth.authenticated(response.data.authToken);
                    this.$store.dispatch('auth/set', true);
                    this.$router
                        .push('dashboard')
                        .catch((failure) => handleNavigationFailure(failure));
                } else {
                    this.failInformation.push({
                        key: '',
                        message: this.$t('register.errorOccurred'),
                    });
                }
            },
            (error) => {
                this.isLoading = false;
            }
        );
    }

    private responseSuccess(response, filterMessageBy: boolean | null = null) {
        if (!response.data) {
            this.failInformation.push({
                key: '',
                message: this.$t('register.errorOccurred'),
            });
            return false;
        }
        if (response.status === 422) {
            this.errorsBag = response.data.errors;
            return false;
        }
        this.parseMessageLocalized(response.data, filterMessageBy);
        return response.data.success;
    }

    private parseMessageLocalized(
        data: { success; messageLocalized },
        filterMessageBy: boolean | null = null
    ) {
        const messageLocalized: Record<any, any> | string | undefined =
            data.messageLocalized;
        const property: string = data.success ? 'success' : 'fail';
        if (filterMessageBy !== null && filterMessageBy !== data.success) {
            return;
        }
        if (typeof messageLocalized === 'object') {
            this[property + 'Information'].push(messageLocalized);
        } else if (typeof messageLocalized === 'string') {
            this[property + 'Information'].push({
                key: '',
                message: messageLocalized,
            });
        }
    }

    private formatEmailAddress() {
        if (this.data.email) {
            this.data.email = this.data.email.toLowerCase();
        }

        if (this.data.emailConfirmation) {
            this.data.emailConfirmation =
                this.data.emailConfirmation.toLowerCase();
        }
    }

    private validateSubmit() {
        const firstnameConfirmation = this.validateFirstname();
        const surnameConfirmation = this.validateSurname();
        const customerNoConfirmation = this.validateCustomerNo();
        const authUserNameConfirmation = this.validateAuthUserName();
        const passwordConfirmation = this.validatePasswordConfirmation();
        const emailConfirmation = this.validateEmailConfirmation();

        const email = this.validateEmail();
        const agreementTerms = this.validateAgreement();

        return (
            firstnameConfirmation &&
            surnameConfirmation &&
            customerNoConfirmation &&
            authUserNameConfirmation &&
            passwordConfirmation &&
            emailConfirmation &&
            email &&
            agreementTerms
        );
    }

    private validatePasswordConfirmation() {
        if (this.data.password !== '') {
            if (
                this.data.password.length < 8 ||
                !this.data.password.match(/[a-z]/) ||
                !this.data.password.match(/[A-Z]/) ||
                !this.data.password.match(/[0-9]/)
            ) {
                this.errorsBag.password = [
                    this.$t('register.password.validation.error'),
                ];
                return false;
            }
        }
        if (
            this.data.password !== '' &&
            this.data.password === this.data.passwordConfirmation
        ) {
            return true;
        }

        if (this.data.password === '') {
            this.errorsBag.password = [this.$t('register.field.required')];
        }

        this.errorsBag.passwordConfirmation = [
            this.$t('register.passwordConfirmation.fail'),
        ];
        return false;
    }

    private validateEmail() {
        const regex =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
        if (regex.test(this.data.email)) {
            return true;
        } else {
            this.errorsBag.email = [this.$t('register.email.validation.error')];
            return false;
        }
        if (
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                this.data.email
            )
        ) {
            return true;
        }
        this.errorsBag.email = [this.$t('register.email.invalid')];
        return false;
    }

    private validateEmailConfirmation() {
        if (
            this.data.email !== '' &&
            this.data.email === this.data.emailConfirmation
        ) {
            return true;
        }
        this.errorsBag.emailConfirmation = [
            this.$t('register.emailConfirmation.fail'),
        ];
        return false;
    }

    private validateAgreement() {
        if (this.data.agreementTerms) {
            return true;
        }
        this.errorsBag.agreementTerms = [this.$t('register.field.required')];
        return false;
    }

    // Firstname
    // Namenszeile 2
    private validateFirstname() {
        if (this.data.firstname !== '' || this.currentTab === 2) {
            return true;
        }
        this.errorsBag.firstname = [this.$t('register.field.required')];
        return false;
    }

    // Surname
    // Namenszeile 1
    private validateSurname() {
        if (this.data.surname !== '') {
            return true;
        }
        if (this.currentTab === 1) {
            this.errorsBag.surname = [this.$t('register.field.required')];
        } else if (this.currentTab === 2) {
            this.errorsBag.nameString1 = [this.$t('register.field.required')];
        }
        return false;
    }

    private validateCustomerNo() {
        if (this.data.customerNo !== null) {
            const regex = /^[1-2]+[0-9]*$/;
            if (regex.test(String(this.data.customerNo))) {
                return true;
            } else {
                this.errorsBag.customerNo = [
                    this.$t('register.customerNo.validation.error'),
                ];
                return false;
            }
        }
        this.errorsBag.customerNo = [this.$t('register.field.required')];
        return false;
    }

    private validateAuthUserName() {
        if (this.data.authUserName !== null) {
            return true;
        }
        this.errorsBag.authUserName = [this.$t('register.field.required')];
        return false;
    }

    private getError(field) {
        if (!this.errorsBag || !this.errorsBag[field]) {
            return null;
        }
        return this.errorsBag[field].reduce(
            (accumulator, currentValue) => accumulator + '<br/>' + currentValue
        );
    }

    get errorFirstname(): string {
        return this.getError('firstname');
    }
    get errorSurname(): string {
        return this.getError('surname');
    }
    get errorCustomerNo(): string {
        return this.getError('customerNo');
    }
    get errorAuthUserName(): string {
        return this.getError('authUserName');
    }
    get errorPassword(): string {
        return this.getError('password');
    }
    get errorPasswordConfirmation(): string {
        return this.getError('passwordConfirmation');
    }
    get errorEmail(): string {
        return this.getError('email');
    }
    get errorEmailConfirmation(): string {
        return this.getError('emailConfirmation');
    }
    get errorAgreement(): string {
        return this.getError('agreementTerms');
    }
    get errorNameString1() {
        return this.getError('nameString1');
    }
    get errorNameString2() {
        return this.getError('nameString2');
    }

    private setHref(text, target, link) {
        if (text.indexOf(target) < 0) {
            return text;
        }
        const formattedTarget = `<a href=${link} target="_blank">${target}</a>`;
        return text
            .split(target)
            .reduce(
                (accumulator, nextValue) =>
                    accumulator + formattedTarget + nextValue
            );
    }

    private clearErrorsBag() {
        this.errorsBag = {
            firstname: null,
            surname: null,
            customerNo: null,
            authUserName: null,
            password: null,
            passwordConfirmation: null,
            email: null,
            emailConfirmation: null,
            agreementTerms: null,
        };
        this.failInformation = [];
        this.successInformation = [];
    }

    private setCurrentTab(tabNumber) {
        this.currentTab = tabNumber;
    }
}
