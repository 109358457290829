<div data-test="left-aside">
    <button
        class="m-aside-left-close m-aside-left-close--skin-dark"
        id="m_aside_left_close_btn"
        @click="$emit('toggleSidebar')"
    >
        <i class="fa fa-times"></i>
    </button>
    <div
        id="m_aside_left"
        class="m-grid__item m-aside-left m-aside-left--skin-dark"
        :class="toggleSidebarClass"
    >
        <!-- BEGIN: Aside Menu -->
        <div
            id="m_ver_menu"
            class="m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark m-scroller ps"
            m-menu-vertical="1"
            m-menu-scrollable="1"
            m-menu-dropdown-timeout="500"
            style="position: relative; height: 100%; overflow: auto"
        >
            <div class="m-aside-menu--welcome">
                <strong v-if="isSME">
                    {{ customerDetails.firstname }}<br />{{
                    customerDetails.surname }}
                </strong>
                <strong v-else>
                    {{ customerDetails.salutation_number }} {{
                    customerDetails.title }}<br />{{ customerDetails.firstname
                    }} {{ customerDetails.surname }}
                </strong>
                <br />
            </div>
            <ul class="m-menu__nav border-bottom pt-2 pb-3 mb-0">
                <li
                    :class="['m-menu__item', currentRouteName === 'dashboard' ? 'm-menu__item--selected' : '']"
                >
                    <router-link :to="'/dashboard'" class="m-menu__link">
                        <i
                            class="m-menu-links-icon-block far fa-user m-menu__link-icon"
                            v-tooltip.right="{
                content: $t('left.aside.customer'),
                class: 'tooltip-left-aside' +
                 (tooltipShownFor.filter((x) => x === 'customer').length <= 1 ? ' tooltip-correction' : ''),
              }"
                            data-id="customer"
                            @mouseover="iconHover"
                        ></i>
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span class="m-menu__link-text"
                                    >{{ $t('left.aside.customer') }}</span
                                >
                            </span>
                        </span>
                    </router-link>
                </li>
                <li
                    :class="['m-menu__item', currentRouteName === 'postbox' ? 'm-menu__item--selected' : '']"
                >
                    <router-link
                        :to="'/dashboard/postbox'"
                        class="m-menu__link"
                    >
                        <i
                            class="m-menu-links-icon-block far fa-envelope m-menu__link-icon"
                            v-tooltip.right="{
                                content: $t('left.aside.postbox'),
                                class: 'tooltip-left-aside' +
                                (tooltipShownFor.filter((x) => x === 'customer').length <= 1 ? ' tooltip-correction' : ''),
                            }"
                            data-id="customer"
                            @mouseover="iconHover"
                        ></i>
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span class="m-menu__link-text"
                                    >{{ $t('left.aside.postbox') }}</span
                                >
                            </span>
                        </span>
                    </router-link>
                </li>
                <li class="m-menu__item">
                    <a
                        @click="toggleOpenChangePasswordModal"
                        class="m-menu__link"
                    >
                        <i
                            class="m-menu-links-icon-block fa fa-key m-menu__link-icon"
                        ></i>
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span class="m-menu__link-text">
                                    {{ $t('protected.password.change') }}
                                </span>
                            </span>
                        </span>
                    </a>
                </li>
                <li v-if="isTwoFactorAvailable" class="m-menu__item">
                    <a
                        @click="toggleOpenTwoFactorAuthenticationModal"
                        class="m-menu__link"
                    >
                        <i class="m-menu__link-icon fa fa-unlock"></i>
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span class="m-menu__link-text"
                                    >{{ $t('asside.two.factor.authentication')
                                    }}</span
                                >
                            </span>
                        </span>
                    </a>
                </li>
                <li class="m-menu__item">
                    <a @click="logout()" class="m-menu__link">
                        <i
                            class="m-menu-links-icon-block m-menu__link-icon fa fa-sign-out-alt text-primary-color"
                            v-tooltip.right="{
                content: $t('asside.signout'),
                class: 'tooltip-left-aside' +
                 (tooltipShownFor.filter((x) => x === 'signout').length <= 1 ? ' tooltip-correction' : ''),
              }"
                            data-id="signout"
                            @mouseover="iconHover"
                        ></i>
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span
                                    class="m-menu__link-text text-primary-color"
                                    >{{ $t('asside.signout') }}</span
                                >
                            </span>
                        </span>
                    </a>
                </li>
            </ul>
            <ul
                class="m-menu__nav m-menu__nav--dropdown-submenu-arrow pt-0 scrollable d-flex flex-column h-100"
            >
                <li class="contracts-title text-grey px-4 mx-2 py-4">
                    <strong>{{ $t('left.aside.contracts') }}</strong>
                </li>
                <li
                    data-test="left-aside-contract-buttons"
                    class="m-menu__item mb-3"
                    :class="{'m-menu__item--selected': selectedContractId === contract.contractId}"
                    v-for="contract in contractsList"
                    :key="contract.contractId"
                >
                    <router-link
                        :to="'/dashboard/'+contract.contractId"
                        class="m-menu__link"
                    >
                        <i
                            class="m-menu__link-icon fa fa-file-contract"
                            v-tooltip.right="{
                content: $t('left.aside.contract') + ': #' + contract.contractId.substring(2,12),
                class: 'tooltip-left-aside in-scrollable' +
                 (tooltipShownFor.filter((x) => x === 'contract' + contract.contractId).length <= 1 ? ' tooltip-correction' : ''),
              }"
                            :data-id="'contract' + contract.contractId"
                            @mouseover="iconHover"
                        ></i>
                        <span class="m-menu__minimized-data"
                            >{{ contract.contractId.substring(2,12) }}</span
                        >
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span class="m-menu__link-text">
                                    <span>
                                        {{ $t('login.customerNumber') }}: {{
                                        contract.contractId.substring(2,12) }}
                                    </span>
                                    <br />
                                    <span
                                        >{{ contract.street }} {{
                                        contract.houseNumber }}</span
                                    ><br />
                                    <span
                                        >{{ contract.zip }} {{ contract.city
                                        }}</span
                                    >
                                    <span class="title mt-3 d-block"
                                        ><strong
                                            >{{ contract.title }}</strong
                                        ></span
                                    >
                                    <span class="d-block">
                                        {{ $t('left.aside.startOfDelivery') }}:
                                        {{ contract.desiredStartDate | date }}
                                    </span>
                                    <span class="d-block">
                                        {{ $t('left.aside.endOfDelivery') }}:
                                        <template v-if="contract.desiredEndDate"
                                            >{{ contract.desiredEndDate | date
                                            }}</template
                                        >
                                        <template
                                            v-else-if="contract.statusCode"
                                            >{{ contract.statusCodeTitle
                                            }}</template
                                        >
                                    </span>
                                </span>
                            </span>
                        </span>
                    </router-link>
                </li>
                <li class="mt-auto menu-contacts">
                    <div class="border-top w-100 left-aside-footer">
                        <div class="text-grey px-4 mx-2 py-4">
                            <strong class="h3 pb-1 d-block"
                                >{{ $t('footer.contact') }}</strong
                            >
                            <div>
                                <a
                                    class="d-block text-grey"
                                    href="mailto:service@stadtwerke-velbert.de"
                                >
                                    service@stadtwerke-velbert.de
                                </a>
                                <a
                                    class="d-block text-grey"
                                    href="tel:02051 988555"
                                    >02051 988 555</a
                                >
                            </div>
                            <div class="mt-3">
                                Montag bis Mittwoch und<br />
                                Freitag 8:00 bis 16:00 Uhr<br />
                                Donnerstag 8:00 bis 17:00 Uhr
                            </div>
                        </div>
                        <ul
                            class="m-menu__nav border-top"
                            v-if="locales.length > 1"
                        >
                            <li
                                class="m-menu__item m-menu__item--submenu"
                                :class="{ 'm-menu__item--open': displayLocaleSelect === true}"
                                aria-haspopup="true"
                                m-menu-submenu-toggle="hover"
                            >
                                <a
                                    @click="toggleLocaleSelect()"
                                    class="m-menu__link m-menu__toggle flag-icon-parent"
                                >
                                    <country-flag
                                        :country="currentLocale"
                                        size="normal"
                                        class="m-menu__link-icon flag-icon"
                                    />
                                    <span class="m-menu__link-text"
                                        >{{ $t('locale.'+currentLocale) }}</span
                                    >
                                </a>
                                <div class="m-menu__submenu">
                                    <span class="m-menu__arrow"></span>
                                    <ul class="m-menu__subnav">
                                        <li
                                            class="m-menu__item"
                                            v-for="locale in locales"
                                            v-if="locale != currentLocale"
                                        >
                                            <a
                                                @click="changeLocale(locale);toggleLocaleSelect();"
                                                class="m-menu__link flag-icon-parent"
                                            >
                                                <country-flag
                                                    :country="locale"
                                                    size="normal"
                                                    class="m-menu__link-icon flag-icon"
                                                />
                                                <span
                                                    class="m-menu__link-title"
                                                >
                                                    <span
                                                        class="m-menu__link-wrap"
                                                    >
                                                        <span
                                                            class="m-menu__link-text"
                                                            >{{
                                                            $t('locale.'+locale)
                                                            }}</span
                                                        >
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <div class="px-4 border-top py-3">
                            <a
                                href="https://www.stadtwerke-velbert.de/datenschutz"
                                class="text-primary-color m-2 d-block"
                                target="_blank"
                                ><span
                                    >{{ $t('left.aside.privacyPolicy') }}</span
                                ></a
                            >
                            <a
                                href="https://www.stadtwerke-velbert.de/impressum"
                                class="text-primary-color m-2 d-block"
                                target="_blank"
                                ><span
                                    >{{ $t('left.aside.impressum') }}</span
                                ></a
                            >
                        </div>
                    </div>
                </li>
            </ul>
            <!-- begin: Aside Footer Area -->
            <div class="sidebar-footer-area" v-if="version !== 'prod'">
                <b>{{ version }}</b>
            </div>
            <!-- END: Aside Footer Area -->
            <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                <div
                    class="ps__thumb-x"
                    tabindex="0"
                    style="left: 0px; width: 0px"
                ></div>
            </div>
            <div class="ps__rail-y" style="top: 0px; right: 4px">
                <div
                    class="ps__thumb-y"
                    tabindex="0"
                    style="top: 0px; height: 0px"
                ></div>
            </div>
        </div>
        <!-- END: Aside Menu -->
    </div>
    <div class="menu-backdrop" @click="$emit('toggleSidebar')"></div>
</div>
