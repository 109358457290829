import { default as Component } from 'vue-class-component';

// Call Render template.
import WithRender from './velbert.html';
import { Dashboard as DashboardParent } from '@/components/pages/dashboard/dashboard';

// Widgets
import { AgentViewWidget } from '../../../widgets/agent-view-widget';
import { VelbertLeftAside } from '@/components/template/left-aside/custom/velbert';
import { WelcomeWidgetV3 } from '@/components/widgets/welcome-widget/welcome-widget-v3';
import { DepositGeneralWidget } from '@/components/widgets/deposit-change-widget/custom/deposit-general-widget';
import { MeterSubmitWidget } from '@/components/widgets/meter-submit-widget';
import { InvoicesGeneralWidget } from '@/components/widgets/invoice-widget/custom/invoices-general-widget';
import { ChangePaymentDataWidget } from '@/components/widgets/change-payment-data-widget';
import { MeterHistoryWidget } from '@/components/widgets/meter-history-widget';
import { ChangeCustomerDataWidget } from '@/components/widgets/change-customer-data-widget';
import { ChangeBillingDataWidget } from '@/components/widgets/change-billing-data-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';
import { DepositPlanWidget } from '@/components/widgets/deposit-plan-widget';

@WithRender
@Component({
    components: {
        // Widgets
        VelbertLeftAside,
        WelcomeWidgetV3,
        DepositGeneralWidget,
        InvoicesGeneralWidget,
        MeterSubmitWidget,
        ChangePaymentDataWidget,
        MeterHistoryWidget,
        ChangeCustomerDataWidget,
        ChangeBillingDataWidget,
        DepositPlanWidget,
        'agent-view-widget': AgentViewWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
    },
})

// @ts-ignore
export class Dashboard extends DashboardParent {
    get contractId(): number {
        return this.$store.state.contracts.contractId;
    }

    get postBoxSelected(): boolean {
        return this.$route.name === 'postbox';
    }

    protected toggleGroup2AndScroll(key: any): void {
        this.toggleGroup2(key);
        this.$nextTick(() => {
            if (this.showGroup2[key]) {
                this.$scrollTo('#' + key);
            }
        });
    }
    get showMeterHistoryWidget(): boolean {
        return !(this.$route.name === 'dashboard' && this.shouldRedirect);
    }
}
