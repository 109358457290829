<div
    class="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-aside--offcanvas-default"
    :class="bodyClass"
>
    <div class="m-grid m-grid--hor m-grid--root m-page">
        <!-- BEGIN: Header -->
        <app-header @toggleSidebar="toggleSidebar"></app-header>
        <!-- END: Header -->
        <!-- begin::Body -->
        <div
            class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body"
        >
            <!-- BEGIN: Left Aside -->
            <velbert-left-aside
                @toggleSidebar="toggleSidebar"
                :toggleSidebar="toggleSidebarState"
                @toggle-modal="toggleOpenChangePasswordModal"
                @toggle-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
            ></velbert-left-aside>
            <!-- END: Left Aside -->
            <!-- begin: Body -->
            <modal-delete-account-widget
                v-if="showDeleteAccountModal"
                @toggle-modal="toggleOpenDeleteAccountModal"
            />
            <modal-password-update-widget
                v-if="showChangePasswordModal"
                @toggle-modal="toggleOpenChangePasswordModal"
            />

            <modal-message-widget
                v-if="showMessageModal"
                :message="$t('widget.modal.message.security.message')"
                @toggle-message-modal="toggleOpenMessageModal"
            ></modal-message-widget>

            <modal-two-factor-authentication-widget
                v-if="showTwoFactorAuthenticationModal"
                @toggle-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
                @toggle-cancel-two-factor-authentication-modal="toggleOpenTwoFactorAuthenticationModal"
            ></modal-two-factor-authentication-widget>

            <div
                class="m-grid__item m-grid__item--fluid m-wrapper wallpaper-wrapper"
                :style="widgetsBackground"
            >
                <div v-if="isDemoMode" class="demo-banner">
                    <span>powered by</span>
                    <img alt="WorkDigital" src="/img/wd_logo.svg" />
                </div>
                <agent-view-widget />
                <call-to-action-banner-widget
                    v-if="showCallToActionBannerWidget"
                />
                <div
                    v-if="contractsDataSet && contractId"
                    class="container main-wrap"
                >
                    <welcome-widget-v3></welcome-widget-v3>
                    <div class="m-content">
                        <!-- ROW -->
                        <div class="row">
                            <div
                                class="col-md-12 col-sm-12 col-xs-12 row-items"
                                v-if="showStatusBarWidget"
                            >
                                <status-bar-widget></status-bar-widget>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-md-6">
                                <deposit-general-widget />
                            </div>
                            <div class="col-12 col-md-6">
                                <deposit-plan-widget />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <meter-submit-widget />
                            </div>
                            <div class="col-12 col-md-6">
                                <meter-history-widget
                                    v-if="showMeterHistoryWidget"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-8">
                                <invoices-general-widget
                                    :showPriceInformationAsterisk="showPriceInformationAsterisk"
                                    @toggleWidget="toggleGroup2AndScroll"
                                />
                            </div>
                            <div class="col-12 col-md-4">
                                <change-payment-data-widget
                                    v-if="stateHasLoaded"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <tariff-overview-widget
                                :showPriceInformationAsterisk="showPriceInformationAsterisk"
                                @closeWidget="closeGroup4"
                            ></tariff-overview-widget>
                        </div>
                        <div class="row" v-if="showPriceInformationAsterisk">
                            <div
                                class="col-12 col-md-12"
                                v-html="$t('widget.to.priceInformation')"
                            ></div>
                        </div>
                        <div
                            class="row mobile-modal"
                            v-if="showChangeBillingDataWidget"
                        >
                            <div class="col row-items mobile-modal__inner">
                                <change-billing-data-widget
                                    @closeWidget="closeGroup2"
                                    id="changeBillingDataWidget"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-else-if="postBoxSelected"
                    class="container main-wrap customer-data-change-no-contracts"
                >
                    <div class="row mb-4">
                        <div class="col-md-12 col-sm-12 col-xs-12 row-items">
                            <post-box-combined-widget></post-box-combined-widget>
                        </div>
                    </div>
                </div>

                <div
                    v-else-if="noContractsAvailable || !contractId"
                    class="container main-wrap customer-data-change-no-contracts"
                >
                    <div class="row mb-4">
                        <div class="col-md-12 col-sm-12 col-xs-12 h2 mb-4">
                            {{ $t('widget.ccd.title') }}
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12 row-items">
                            <change-customer-data-widget
                                @delete-toggle-modal="toggleOpenDeleteAccountModal"
                            ></change-customer-data-widget>
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="container main-wrap"
                    style="height: 90vh"
                ></div>
            </div>
            <!-- END: Body -->
        </div>
        <!-- end:: Body -->
    </div>
    <!-- end:: Page -->
    <!-- begin::Scroll Top -->
    <scroll-top></scroll-top>
    <!-- end::Scroll Top -->
</div>
